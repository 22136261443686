import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, ContentChildren, forwardRef, Directive, NgModule } from '@angular/core';
import { MAT_LEGACY_OPTION_PARENT_COMPONENT, MAT_LEGACY_OPTGROUP, MatLegacyOption, MatLegacyOptionModule } from '@angular/material/legacy-core';
import { _MatAutocompleteBase, _MatAutocompleteTriggerBase, _MatAutocompleteOriginBase, MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/autocomplete';
const _c0 = ["*"];
function MatLegacyAutocomplete_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1, 0);
    i0.ɵɵprojection(2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const formFieldId_r1 = ctx.id;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("id", ctx_r1.id)("ngClass", ctx_r1._classList);
    i0.ɵɵattribute("aria-label", ctx_r1.ariaLabel || null)("aria-labelledby", ctx_r1._getPanelAriaLabelledby(formFieldId_r1));
  }
}
export { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS as MAT_LEGACY_AUTOCOMPLETE_DEFAULT_OPTIONS, MAT_AUTOCOMPLETE_DEFAULT_OPTIONS_FACTORY as MAT_LEGACY_AUTOCOMPLETE_DEFAULT_OPTIONS_FACTORY, MAT_AUTOCOMPLETE_SCROLL_STRATEGY as MAT_LEGACY_AUTOCOMPLETE_SCROLL_STRATEGY, MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY as MAT_LEGACY_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY, MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER as MAT_LEGACY_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER, MatAutocompleteSelectedEvent as MatLegacyAutocompleteSelectedEvent, getMatAutocompleteMissingPanelError as getMatLegacyAutocompleteMissingPanelError } from '@angular/material/autocomplete';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatCommonModule } from '@angular/material/core';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * @deprecated Use `MatAutocomplete` from `@angular/material/autocomplete` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacyAutocomplete extends _MatAutocompleteBase {
  constructor() {
    super(...arguments);
    this._visibleClass = 'mat-autocomplete-visible';
    this._hiddenClass = 'mat-autocomplete-hidden';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵMatLegacyAutocomplete_BaseFactory;
      return function MatLegacyAutocomplete_Factory(__ngFactoryType__) {
        return (ɵMatLegacyAutocomplete_BaseFactory || (ɵMatLegacyAutocomplete_BaseFactory = i0.ɵɵgetInheritedFactory(MatLegacyAutocomplete)))(__ngFactoryType__ || MatLegacyAutocomplete);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MatLegacyAutocomplete,
      selectors: [["mat-autocomplete"]],
      contentQueries: function MatLegacyAutocomplete_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, MAT_LEGACY_OPTGROUP, 5);
          i0.ɵɵcontentQuery(dirIndex, MatLegacyOption, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.optionGroups = _t);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.options = _t);
        }
      },
      hostAttrs: ["ngSkipHydration", "", 1, "mat-autocomplete"],
      inputs: {
        disableRipple: "disableRipple"
      },
      exportAs: ["matAutocomplete"],
      features: [i0.ɵɵProvidersFeature([{
        provide: MAT_LEGACY_OPTION_PARENT_COMPONENT,
        useExisting: MatLegacyAutocomplete
      }]), i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      consts: [["panel", ""], ["role", "listbox", 1, "mat-autocomplete-panel", 3, "id", "ngClass"]],
      template: function MatLegacyAutocomplete_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, MatLegacyAutocomplete_ng_template_0_Template, 3, 4, "ng-template");
        }
      },
      dependencies: [i1.NgClass],
      styles: [".mat-autocomplete-panel{min-width:112px;max-width:280px;overflow:auto;-webkit-overflow-scrolling:touch;visibility:hidden;max-width:none;max-height:256px;position:relative;width:100%;border-bottom-left-radius:4px;border-bottom-right-radius:4px}.mat-autocomplete-panel.mat-autocomplete-visible{visibility:visible}.mat-autocomplete-panel.mat-autocomplete-hidden{visibility:hidden}.mat-autocomplete-panel-above .mat-autocomplete-panel{border-radius:0;border-top-left-radius:4px;border-top-right-radius:4px}.mat-autocomplete-panel .mat-divider-horizontal{margin-top:-1px}.cdk-high-contrast-active .mat-autocomplete-panel{outline:solid 1px}mat-autocomplete{display:none}"],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatLegacyAutocomplete, [{
    type: Component,
    args: [{
      selector: 'mat-autocomplete',
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      exportAs: 'matAutocomplete',
      inputs: ['disableRipple'],
      host: {
        'class': 'mat-autocomplete',
        'ngSkipHydration': ''
      },
      providers: [{
        provide: MAT_LEGACY_OPTION_PARENT_COMPONENT,
        useExisting: MatLegacyAutocomplete
      }],
      template: "<ng-template let-formFieldId=\"id\">\n  <div class=\"mat-autocomplete-panel\"\n       role=\"listbox\"\n       [id]=\"id\"\n       [attr.aria-label]=\"ariaLabel || null\"\n       [attr.aria-labelledby]=\"_getPanelAriaLabelledby(formFieldId)\"\n       [ngClass]=\"_classList\"\n       #panel>\n    <ng-content></ng-content>\n  </div>\n</ng-template>\n",
      styles: [".mat-autocomplete-panel{min-width:112px;max-width:280px;overflow:auto;-webkit-overflow-scrolling:touch;visibility:hidden;max-width:none;max-height:256px;position:relative;width:100%;border-bottom-left-radius:4px;border-bottom-right-radius:4px}.mat-autocomplete-panel.mat-autocomplete-visible{visibility:visible}.mat-autocomplete-panel.mat-autocomplete-hidden{visibility:hidden}.mat-autocomplete-panel-above .mat-autocomplete-panel{border-radius:0;border-top-left-radius:4px;border-top-right-radius:4px}.mat-autocomplete-panel .mat-divider-horizontal{margin-top:-1px}.cdk-high-contrast-active .mat-autocomplete-panel{outline:solid 1px}mat-autocomplete{display:none}"]
    }]
  }], null, {
    optionGroups: [{
      type: ContentChildren,
      args: [MAT_LEGACY_OPTGROUP, {
        descendants: true
      }]
    }],
    options: [{
      type: ContentChildren,
      args: [MatLegacyOption, {
        descendants: true
      }]
    }]
  });
})();

/**
 * Provider that allows the autocomplete to register as a ControlValueAccessor.
 * @docs-private
 * @deprecated Use `MAT_AUTOCOMPLETE_VALUE_ACCESSOR` from `@angular/material/autocomplete` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
const MAT_LEGACY_AUTOCOMPLETE_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MatLegacyAutocompleteTrigger),
  multi: true
};
/**
 * @deprecated Use `MatAutocompleteTrigger` from `@angular/material/autocomplete` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacyAutocompleteTrigger extends _MatAutocompleteTriggerBase {
  constructor() {
    super(...arguments);
    this._aboveClass = 'mat-autocomplete-panel-above';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵMatLegacyAutocompleteTrigger_BaseFactory;
      return function MatLegacyAutocompleteTrigger_Factory(__ngFactoryType__) {
        return (ɵMatLegacyAutocompleteTrigger_BaseFactory || (ɵMatLegacyAutocompleteTrigger_BaseFactory = i0.ɵɵgetInheritedFactory(MatLegacyAutocompleteTrigger)))(__ngFactoryType__ || MatLegacyAutocompleteTrigger);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatLegacyAutocompleteTrigger,
      selectors: [["input", "matAutocomplete", ""], ["textarea", "matAutocomplete", ""]],
      hostAttrs: [1, "mat-autocomplete-trigger"],
      hostVars: 7,
      hostBindings: function MatLegacyAutocompleteTrigger_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("focusin", function MatLegacyAutocompleteTrigger_focusin_HostBindingHandler() {
            return ctx._handleFocus();
          })("blur", function MatLegacyAutocompleteTrigger_blur_HostBindingHandler() {
            return ctx._onTouched();
          })("input", function MatLegacyAutocompleteTrigger_input_HostBindingHandler($event) {
            return ctx._handleInput($event);
          })("keydown", function MatLegacyAutocompleteTrigger_keydown_HostBindingHandler($event) {
            return ctx._handleKeydown($event);
          })("click", function MatLegacyAutocompleteTrigger_click_HostBindingHandler() {
            return ctx._handleClick();
          });
        }
        if (rf & 2) {
          i0.ɵɵattribute("autocomplete", ctx.autocompleteAttribute)("role", ctx.autocompleteDisabled ? null : "combobox")("aria-autocomplete", ctx.autocompleteDisabled ? null : "list")("aria-activedescendant", ctx.panelOpen && ctx.activeOption ? ctx.activeOption.id : null)("aria-expanded", ctx.autocompleteDisabled ? null : ctx.panelOpen.toString())("aria-owns", ctx.autocompleteDisabled || !ctx.panelOpen ? null : ctx.autocomplete == null ? null : ctx.autocomplete.id)("aria-haspopup", ctx.autocompleteDisabled ? null : "listbox");
        }
      },
      exportAs: ["matAutocompleteTrigger"],
      features: [i0.ɵɵProvidersFeature([MAT_LEGACY_AUTOCOMPLETE_VALUE_ACCESSOR]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatLegacyAutocompleteTrigger, [{
    type: Directive,
    args: [{
      selector: `input[matAutocomplete], textarea[matAutocomplete]`,
      host: {
        'class': 'mat-autocomplete-trigger',
        '[attr.autocomplete]': 'autocompleteAttribute',
        '[attr.role]': 'autocompleteDisabled ? null : "combobox"',
        '[attr.aria-autocomplete]': 'autocompleteDisabled ? null : "list"',
        '[attr.aria-activedescendant]': '(panelOpen && activeOption) ? activeOption.id : null',
        '[attr.aria-expanded]': 'autocompleteDisabled ? null : panelOpen.toString()',
        '[attr.aria-owns]': '(autocompleteDisabled || !panelOpen) ? null : autocomplete?.id',
        '[attr.aria-haspopup]': 'autocompleteDisabled ? null : "listbox"',
        // Note: we use `focusin`, as opposed to `focus`, in order to open the panel
        // a little earlier. This avoids issues where IE delays the focusing of the input.
        '(focusin)': '_handleFocus()',
        '(blur)': '_onTouched()',
        '(input)': '_handleInput($event)',
        '(keydown)': '_handleKeydown($event)',
        '(click)': '_handleClick()'
      },
      exportAs: 'matAutocompleteTrigger',
      providers: [MAT_LEGACY_AUTOCOMPLETE_VALUE_ACCESSOR]
    }]
  }], null, null);
})();

/**
 * Directive applied to an element to make it usable
 * as a connection point for an autocomplete panel.
 * @deprecated Use `MatAutocompleteOrigin` from `@angular/material/autocomplete` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacyAutocompleteOrigin extends _MatAutocompleteOriginBase {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵMatLegacyAutocompleteOrigin_BaseFactory;
      return function MatLegacyAutocompleteOrigin_Factory(__ngFactoryType__) {
        return (ɵMatLegacyAutocompleteOrigin_BaseFactory || (ɵMatLegacyAutocompleteOrigin_BaseFactory = i0.ɵɵgetInheritedFactory(MatLegacyAutocompleteOrigin)))(__ngFactoryType__ || MatLegacyAutocompleteOrigin);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatLegacyAutocompleteOrigin,
      selectors: [["", "matAutocompleteOrigin", ""]],
      exportAs: ["matAutocompleteOrigin"],
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatLegacyAutocompleteOrigin, [{
    type: Directive,
    args: [{
      selector: '[matAutocompleteOrigin]',
      exportAs: 'matAutocompleteOrigin'
    }]
  }], null, null);
})();

/**
 * @deprecated Use `MatAutocompleteModule` from `@angular/material/autocomplete` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacyAutocompleteModule {
  static {
    this.ɵfac = function MatLegacyAutocompleteModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MatLegacyAutocompleteModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatLegacyAutocompleteModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER],
      imports: [OverlayModule, MatLegacyOptionModule, MatCommonModule, CommonModule, CdkScrollableModule, MatLegacyOptionModule, MatCommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatLegacyAutocompleteModule, [{
    type: NgModule,
    args: [{
      imports: [OverlayModule, MatLegacyOptionModule, MatCommonModule, CommonModule],
      exports: [MatLegacyAutocomplete, MatLegacyAutocompleteTrigger, MatLegacyAutocompleteOrigin, CdkScrollableModule, MatLegacyOptionModule, MatCommonModule],
      declarations: [MatLegacyAutocomplete, MatLegacyAutocompleteTrigger, MatLegacyAutocompleteOrigin],
      providers: [MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { MAT_LEGACY_AUTOCOMPLETE_VALUE_ACCESSOR, MatLegacyAutocomplete, MatLegacyAutocompleteModule, MatLegacyAutocompleteOrigin, MatLegacyAutocompleteTrigger };
