<mat-form-field [appearance]="appearance">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    #input
    matInput
    type="text"
    [attr.data-test]="controlName + '-input'"
    [matAutocomplete]="auto"
    [formControl]="formControl"
    (input)="onInput($event)"
    (blur)="onBlur($event)"
  />

  <mat-error>{{ validationErrorMessage }}</mat-error>
  <mat-hint>{{ hint }}</mat-hint>

  @if (!empty && !formControl.disabled) {
    <etn-icon
      matSuffix
      [name]="clearIcon.name"
      [size]="clearIcon.size"
      [attr.data-test]="'clear-' + (controlName || 'autocomplete')"
      (click)="onClear($event, input)"
    ></etn-icon>
  }
</mat-form-field>

@if (searching && !hideProgressBar) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

<mat-autocomplete
  #auto="matAutocomplete"
  classList="etn-autocomplete"
  [displayWith]="displayWithFn"
  (optionSelected)="onOptionSelected($event)"
  (opened)="panelOpenedChange(true)"
  (closed)="panelOpenedChange(false)"
>
  @for (option of autocompleteOptions; track option.id) {
    <mat-option
      [value]="option.id"
      [attr.data-test]="controlName + '-option-' + option.id"
      [innerHTML]="option.name"
    ></mat-option>
  }
</mat-autocomplete>
