import { Address, AddressType, NEW, Nil } from '@model';
import { Bounds, LatLng } from '@model/geography';
import { isNil } from 'lodash-es';

import {
  AddressComponent,
  AddressComponentType,
  Place,
} from './address-picker.types';

export function serializeAddress(address: Address | Nil): Place | Nil {
  if (isNil(address) || isNil(address.latitude) || isNil(address.longitude)) {
    return null;
  }
  return {
    name: '',
    formatted_address: address.formattedAddress ?? undefined,
    geometry: {
      location: new LatLng(address.latitude, address.longitude),
      viewport: new Bounds(),
    },
  };
}

export function deserializeAddress(place: Place | Nil): Address | undefined {
  if (isNil(place)) {
    return undefined;
  }
  return {
    city: getCity(place) ?? undefined,
    country: getCountry(place) ?? undefined,
    street: getStreet(place) ?? undefined,
    streetNumber: getStreetNumber(place) ?? undefined,
    zipCode: getPostalCode(place) ?? undefined,
    formattedAddress: place.formatted_address,
    latitude: place.geometry?.location?.lat(),
    longitude: place.geometry?.location?.lng(),
    id: place.place_id ?? NEW,
    type: AddressType.Default,
  };
}

export function getCountry({ address_components }: Place): string | Nil {
  return getComponentShortNameByType(
    address_components,
    AddressComponentType.Country,
  );
}

export function getStreetNumber({ address_components }: Place): string | Nil {
  return getComponentLongNameByType(
    address_components,
    AddressComponentType.StreetNumber,
  );
}

export function getCity({ address_components }: Place): string | Nil {
  return (
    getComponentLongNameByType(address_components, AddressComponentType.City) ??
    getComponentLongNameByType(
      address_components,
      AddressComponentType.PostalTown,
    )
  );
}

export function getPostalCode({ address_components }: Place): string | Nil {
  return getComponentLongNameByType(
    address_components,
    AddressComponentType.PostalCode,
  );
}

export function getStreet({ address_components }: Place): string | Nil {
  return getComponentLongNameByType(
    address_components,
    AddressComponentType.Street,
  );
}

function getComponentLongNameByType(
  components: AddressComponent[] | Nil,
  type: AddressComponentType,
): string | Nil {
  if (isNil(components)) {
    return undefined;
  }
  const component = components.find(({ types }) => {
    return types.includes(type);
  });
  return component ? component.long_name : undefined;
}

function getComponentShortNameByType(
  components: AddressComponent[] | Nil,
  type: AddressComponentType,
): string | Nil {
  if (isNil(components)) {
    return undefined;
  }
  const component = components.find(({ types }) => {
    return types.includes(type);
  });
  return component ? component.short_name : undefined;
}
