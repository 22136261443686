import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatLegacyAutocomplete as MatAutocomplete,
  MatLegacyAutocompleteModule as MatAutocompleteModule,
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
  MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
} from '@angular/material/legacy-autocomplete';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { isNotNil } from '@core/is-not-nil';
import { Id, Nil } from '@model';
import { ActionComponent } from '@ui/action';
import {
  AbstractFormFieldComponent,
  CONTROL_CONTAINER_VIEW_PROVIDER,
} from '@ui/form';
import { Icon, IconComponent, IconSize } from '@ui/icon';
import { SelectableItem } from '@ui/selectable-item';
import { find, get, isNil, isNumber } from 'lodash-es';

@Component({
  selector: 'etn-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  standalone: true,
  providers: [
    {
      provide: AbstractFormFieldComponent,
      useExisting: forwardRef(() => {
        return AutocompleteComponent;
      }),
    },
  ],
  imports: [
    ActionComponent,
    CommonModule,
    FormsModule,
    IconComponent,
    MatAutocompleteModule,
    MatInputModule,
    MatProgressBarModule,
    ReactiveFormsModule,
  ],
  viewProviders: [CONTROL_CONTAINER_VIEW_PROVIDER],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent extends AbstractFormFieldComponent<Id> {
  @Input() public set values(values: SelectableItem[] | Nil) {
    this.autocompleteOptions = values ?? [];
    this.searching = false;
    this.updateSelectedOptions(this.autocompleteOptions, this.value);
  }
  @Input() public hideProgressBar = false;

  @Output() public search = new EventEmitter<string>();
  @Output() public valueSelect = new EventEmitter<string>();
  @Output() public clear = new EventEmitter<void>();
  @Output() public openedChange = new EventEmitter<boolean>();

  @ViewChild(MatAutocompleteTrigger) public autoComplete:
    | MatAutocompleteTrigger
    | Nil;

  @ViewChild(MatAutocomplete) private matAutocomplete: MatAutocomplete | Nil;
  public get panel(): ElementRef | Nil {
    return this.matAutocomplete?.panel;
  }

  public searching = false;
  public autocompleteOptions: SelectableItem[] = [];
  public opened = false;
  public displayWithFn = this.displayWith.bind(this);

  public clearIcon: Icon = {
    name: 'clear',
    size: IconSize.Small,
  };

  /*
   * HACK: If the autocomplete overylay is opened and the user scroll down,
   * the overlay will overlap the top bar. This issue can be reproduce
   * even on the mat-autocomplete official page. In order to prevent this
   * issue, we close the autocomplete if the user scroll outside the overlay.
   * @param event
   */
  @HostListener('window:wheel', ['$event']) public onScrollEvent(
    event: WheelEvent,
  ) {
    if (isNotNil(this.autoComplete) && this.opened) {
      this.autoComplete.updatePosition();
      const target = event.target as HTMLElement;
      if (!target.className.startsWith('mat-option')) {
        this.autoComplete.closePanel();
      }
    }
  }

  public onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.valueSelect.emit(event.option.value);
  }

  public onInput(event: any): void {
    this.searching = true;
    this.search.emit(event.target.value);
  }

  public onBlur(event: FocusEvent): void {
    const target = event.relatedTarget as HTMLElement;
    /*
     * Hack: To check if user has selected an option from auto-complete dropdown and
     * if yes, restrict the blur logic.
     */
    if (isNotNil(target) && target.className.startsWith('mat-option')) {
      return;
    }

    if (isNotNil(this.value)) {
      const selectedOption = find(this.autocompleteOptions, { id: this.value });
      if (isNil(selectedOption)) {
        this.formControl.setValue(null);
        this.clear.emit();
      }
    }
  }

  public override get empty(): boolean {
    return isNil(this.formControl.value);
  }

  public onClear(event: MouseEvent, input: HTMLInputElement): void {
    event.preventDefault();
    event.stopPropagation();
    this.formControl.setValue(null);
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    input.value = '';
    this.clear.emit();
  }

  public panelOpenedChange(event: boolean) {
    this.opened = event;
    this.openedChange.emit(event);
  }

  private updateSelectedOptions(
    autocompleteOptions: SelectableItem[],
    value: Id | Nil,
  ) {
    if (isNotNil(value)) {
      const selectedOption = find(autocompleteOptions, { id: this.value });
      const id = get(selectedOption, 'id');
      if (isNotNil(id)) {
        this.formControl.setValue(id);
      }
    }
  }

  private displayWith(id: Id | Nil): string {
    if (isNil(id)) {
      return '';
    }
    const option = find(this.autocompleteOptions, { id });
    if (isNil(option) || isNumber(option)) {
      return '';
    }
    return option.name;
  }
}
