import {
  AfterViewInit,
  Directive,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { isNotNil } from '@core/is-not-nil';
import { Nil } from '@model';
import { isNil } from 'lodash-es';
import { Subject, fromEvent } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

import { AutocompleteComponent } from './autocomplete.component';

/** The height of the select items in `em` units. */
const SELECT_ITEM_HEIGHT_EM = 3;

@Directive({
  selector: '[etnAutocompleteInfiniteScroll]',
  standalone: true,
})
export class AutocompleteInfiniteScrollDirective
  implements OnInit, OnDestroy, AfterViewInit
{
  public constructor(
    private autocomplete: AutocompleteComponent,
    private ngZone: NgZone,
  ) {}
  @Input() public threshold = '15%';
  @Input() public debounceTime = 150;
  @Input() public complete: boolean | Nil;
  @Output() public infiniteScroll = new EventEmitter<void>();

  private panel: Element | Nil;
  private thrPx = 0;
  private thrPc = 0;
  private singleOptionHeight = SELECT_ITEM_HEIGHT_EM;

  private destroySubject$ = new Subject<void>();

  public ngOnInit() {
    this.evaluateThreshold();
    this.infiniteScroll.emit();
  }

  public ngAfterViewInit() {
    this.autocomplete.openedChange
      .pipe(takeUntil(this.destroySubject$), debounceTime(this.debounceTime))
      .subscribe((opened) => {
        if (opened) {
          this.panel = this.autocomplete.panel?.nativeElement;
          if (isNotNil(this.panel)) {
            this.singleOptionHeight = this.getSelectItemHeightPx(this.panel);
          }
          this.registerScrollListener();
        }
      });
  }

  public ngOnDestroy() {
    this.destroySubject$.next();
  }

  private evaluateThreshold() {
    if (this.threshold.lastIndexOf('%') > -1) {
      this.thrPx = 0;
      this.thrPc = parseFloat(this.threshold) / 100;
    } else {
      this.thrPx = parseFloat(this.threshold);
      this.thrPc = 0;
    }
  }

  private registerScrollListener() {
    if (isNil(this.panel)) {
      return;
    }
    fromEvent(this.panel, 'scroll')
      .pipe(
        takeUntil(this.destroySubject$),
        debounceTime(this.debounceTime),
        tap((event) => {
          this.handleScrollEvent(event);
        }),
      )
      .subscribe();
  }

  private handleScrollEvent(event: any) {
    this.ngZone.runOutsideAngular(() => {
      if (this.complete) {
        return;
      }
      const countOfRenderedOptions =
        this.autocomplete.autocompleteOptions?.length ?? 0;
      const infiniteScrollDistance =
        this.singleOptionHeight * countOfRenderedOptions;
      const threshold =
        this.thrPc !== 0 ? infiniteScrollDistance * this.thrPc : this.thrPx;

      const scrolledDistance =
        (this.panel?.clientHeight ?? 0) + event.target.scrollTop;

      if (scrolledDistance + threshold >= infiniteScrollDistance) {
        this.ngZone.run(() => {
          this.autocomplete.searching = true;
          this.infiniteScroll.emit();
        });
      }
    });
  }

  private getSelectItemHeightPx(panel: Element): number {
    return parseFloat(getComputedStyle(panel).fontSize) * SELECT_ITEM_HEIGHT_EM;
  }
}
