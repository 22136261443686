export type Place = google.maps.places.PlaceResult;
export type AddressComponent = google.maps.GeocoderAddressComponent;
export enum AddressComponentType {
  City = 'locality',
  Country = 'country',
  PostalCode = 'postal_code',
  PostalTown = 'postal_town',
  Street = 'route',
  StreetNumber = 'street_number',
}
export enum AddressComponentName {
  Short = 'short_name',
  Long = 'long_name',
}
